import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { ColDef, RowSelectionOptions } from "ag-grid-enterprise";
import { toast } from "react-toastify";
import { EsopDetails } from "./EsopStats";
import { useAdminUserDetails } from "../../../queries/companyStats/esopStats";
import { PrimaryCTAButton } from "../../../components/CTAButtonComponents";
import { GenericDateRender, WarningDialog } from "./EsopAgComponents";
import { VStack } from "../../../components/utils";
import { EsopCompanyDetails } from "../../../types/companyStats";
import { AddBillingDetails, WentLivePopup } from "./CompanyPopups";

type UserDetails = {
  id: string;
  userId: bigint;
  email: string;
  role: UserRole;
  name: string;
  isCompanySpecificRole: boolean;
  userSince: Date;
  designation: string;
};
type UserRole = {
  name: string;
  id: string;
};
export type UserRowDetails = {
  userId: string;
  full_name: string;
  email: string;
  role: string;
};
function CompanyDetailsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const gridApi = useRef<AgGridReact>(null);
  const companyDetails: EsopDetails = location?.state;
  const [adminDetails, setAdminDetails] = useState([]);
  const [companyDetailsData, setCompanyDetailsData] =
    useState<EsopCompanyDetails>();
  const [deleteUser, setDeleteUser] = useState(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(true);
  const [userDetails, setUserDetails] = useState<UserRowDetails[]>([]);
  const { mutate: mutateAdminDetails } = useAdminUserDetails();
  const [isWentLivePopup, setWentLivePopup] = useState(false);
  const [isBillDataPopup, setIsBillDataPopup] = useState(false);

  useEffect(() => {
    if (companyDetails?.id) {
      mutateAdminDetails(companyDetails.id, {
        onSuccess: (data: any) => {
          setCompanyDetailsData(data);
        },
        onError: (error: any) => {
          toast(error.data.message, { type: "error", autoClose: 2000 });
        },
      });
    }
  }, [companyDetails]);
  const handleDelete = () => {
    setDeleteUser(true);
    setUserDetails(gridApi.current?.api.getSelectedRows() || []);
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      filter: true,
      minWidth: 150,
      flex: 1,
    }),
    [],
  );
  const rowSelection = useMemo<RowSelectionOptions>(() => {
    return {
      mode: "singleRow",
      enableClickSelection: true,
    };
  }, []);
  const rowsData = useMemo(
    () =>
      companyDetailsData?.adminDetails.map((admin) => ({
        userId: admin.userId,
        full_name: admin.name,
        email: admin.email,
        role: admin.role.name.replaceAll("_", " ").toLowerCase(),
      })),
    [companyDetailsData],
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "User Name",
      field: "full_name",
      filter: "agTextColumnFilter",
      cellClass: "capitalize",
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "User Role",
      field: "role",
      filter: "agTextColumnFilter",
      cellClass: "capitalize",
    },
  ];
  const liquidityColDefs: ColDef[] = [
    {
      headerName: "Event Name",
      field: "eventName",
      cellClass: "capitalize",
    },
    {
      headerName: "Event State",
      field: "eventState",
      cellClass: "capitalize",
      sort: "desc",
    },
    {
      headerName: "Start Date",
      field: "startDate",
      cellRenderer: GenericDateRender,
      sort: "asc",
    },
    {
      headerName: "End Date",
      field: "endDate",
      cellRenderer: GenericDateRender,
    },
    {
      headerName: "Event Type",
      field: "eventType",
    },
    {
      headerName: "Total Eligibility",
      field: "totalEligibility",
    },
    {
      headerName: "Total Willingness",
      field: "totalWillingness",
    },
    {
      headerName: "In Progress",
      field: "inProgress",
    },
    {
      headerName: "Actual Cashout",
      field: "actualCashoutAmount",
    },
  ];
  const liquidityRowData = useMemo(
    () =>
      companyDetailsData?.companyMonetaryDetails.map((event: any) => ({
        ...event,
      })),
    [companyDetailsData],
  );
  const employeeRowData = useMemo(
    () =>
      companyDetailsData?.employeeDetails.map((employee: any) => ({
        ...employee,
      })),
    [companyDetailsData],
  );
  const employeeColDefs: ColDef[] = [
    {
      headerName: "Employee Name",
      field: "name",
      cellClass: "capitalize",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Total Granted",
      field: "totalGranted",
    },
    {
      headerName: "Total Vested",
      field: "totalVested",
    },
    {
      headerName: "Total Lapsed",
      field: "totalLapsed",
    },
    {
      headerName: "Total Sold",
      field: "totalSold",
    },
    {
      headerName: "Total Forfeited",
      field: "totalForfeited",
    },
    {
      headerName: "Employee Since",
      field: "createdAt",
      cellRenderer: GenericDateRender,
    },
  ];
  return (
    <VStack className="gap-4">
      <div className="p-5 rounded-lg shadow-custom ">
        <div className="grid grid-cols-2">
          <div className="text-2xl flex">
            Company Name :
            <div className="font-bold ms-2">
              {companyDetailsData?.companyName}
            </div>
          </div>
          <div className="text-xl flex">
            CIN :
            <div className="font-bold ms-2"> {companyDetailsData?.cin}</div>
          </div>
          <div className="text-xl flex">
            DBA :
            <div className="font-bold ms-2">{companyDetailsData?.dbaName}</div>
          </div>
          <div className="text-xl flex">
            Went Live on :
            <div className="font-bold ms-2">
              {companyDetailsData?.wentLiveOn
                ? new Date(companyDetailsData?.wentLiveOn).toLocaleDateString(
                    "en-IN",
                  )
                : "N/A"}
            </div>
          </div>
        </div>
        <div className="flex items-end gap-3 justify-end">
          {companyDetailsData?.wentLiveOn === null && (
            <PrimaryCTAButton
              onClick={() => {
                setWentLivePopup(!isWentLivePopup);
              }}
            >
              Add Live Date
            </PrimaryCTAButton>
          )}
          {companyDetailsData?.billingProfile.length! === 0 && (
            <PrimaryCTAButton
              onClick={() => {
                setIsBillDataPopup(!isBillDataPopup);
              }}
              disabled={companyDetailsData?.wentLiveOn === null}
            >
              Add Billing Details
            </PrimaryCTAButton>
          )}
          {companyDetailsData?.billingProfile.length! > 0 && (
            <PrimaryCTAButton
              onClick={() => {
                navigate("/billingDetails", { state: companyDetailsData });
              }}
            >
              Go To Billing Page
            </PrimaryCTAButton>
          )}
          {isWentLivePopup && (
            <WentLivePopup
              isShow={isWentLivePopup}
              companyId={companyDetails.id}
              onClose={() => {
                setWentLivePopup(!isWentLivePopup);
                if (companyDetails?.id) {
                  mutateAdminDetails(companyDetails.id, {
                    onSuccess: (data: any) => {
                      setCompanyDetailsData(data);
                      setDisableDeleteBtn(true);
                    },
                    onError: (error: any) => {
                      toast(error.data.message, {
                        type: "error",
                        autoClose: 2000,
                      });
                    },
                  });
                }
              }}
              companyDetails={companyDetails}
            />
          )}
          {isBillDataPopup && (
            <AddBillingDetails
              isShow={isBillDataPopup}
              companyId={companyDetails.id}
              companyDetails={companyDetailsData}
              onClose={() => {
                setIsBillDataPopup(!isBillDataPopup);
                if (companyDetails?.id) {
                  mutateAdminDetails(companyDetails.id, {
                    onSuccess: (data: any) => {
                      setCompanyDetailsData(data);
                      setDisableDeleteBtn(true);
                    },
                    onError: (error: any) => {
                      toast(error.data.message, {
                        type: "error",
                        autoClose: 2000,
                      });
                    },
                  });
                }
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-between py-5 gap-4">
        <div className="px-5 py-2 shadow-custom rounded-xl text-[16px] w-1/3 flex flex-col gap-2">
          <div className="text-2xl font-semibold">Plan Details</div>
          <div>
            <div className="flex justify-between font-semibold">
              Total Plan Pool :
              <div className="">{companyDetails?.totalActivePlanPoolSize}</div>
            </div>
            <div className="flex justify-between ">
              Total Plans :{" "}
              <div className="">{companyDetails?.totalNumberOfPlans}</div>
            </div>
          </div>
        </div>
        <div className="px-5 py-2 shadow-custom rounded-xl text-[16px] w-1/3 flex flex-col gap-2">
          <div className="text-2xl font-semibold">Employee Details</div>
          <div>
            <div className="flex justify-between font-semibold">
              Total Employees :
              <div className="">
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalNumberOfEmployees || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between font-semibold">
              Employed :
              <div className="">
                {Intl.NumberFormat("en-IN").format(
                  Number(companyDetailsData?.totalNumberOfEmployees) -
                    Number(companyDetailsData?.totalNumberOfResignedEmployees),
                )}
              </div>
            </div>
            <div className="flex justify-between font-semibold">
              Resigned :{" "}
              <div className="">
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalNumberOfResignedEmployees || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between font-semibold">
              Access Given :{" "}
              <div className="">
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.numberOfEmployeesGivenAccess || 0,
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="px-5 py-2 rounded-lg shadow-custom w-1/3 flex flex-col gap-2">
          <div className="text-2xl font-semibold">Pool Details</div>
          <div className="text-[16px] space-y-2">
            <div className="flex justify-between ">
              Total Granted :
              <div>
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalGranted || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              Total Vested :
              <div>
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalVested || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              Total Surrendered :
              <div>
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalSurrendered || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between font-semibold ">
              Total Stock Exercise :
              <div>
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalStockExercise || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              Total Lapsed :
              <div>
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalLapsed || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              Total Cashed out :
              <div>
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalCashedout || 0,
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              Total Sold :
              <div>
                {Intl.NumberFormat("en-IN").format(
                  companyDetailsData?.totalSold || 0,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {companyDetailsData?.companyMonetaryDetails &&
        companyDetailsData?.companyMonetaryDetails.length > 0 && (
          <div className="py-5 ag-theme-quartz h-[calc(100vh-50vh)]">
            <div className="flex pb-4 justify-between">
              <div className="text-2xl font-bold">Liquidity Details</div>
            </div>
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={liquidityColDefs}
              rowData={liquidityRowData}
              suppressRowTransform={true}
              pagination={true}
              animateRows={true}
              getRowStyle={(params) => {
                if (params.rowIndex % 2 === 0) {
                  return { background: "#f8f8f8" };
                } else {
                  return { background: "#ffffff" };
                }
              }}
            />
          </div>
        )}
      {companyDetailsData?.employeeDetails &&
        companyDetailsData?.employeeDetails.length > 0 && (
          <div className="py-5 ag-theme-quartz h-[calc(100vh-50vh)]">
            <div className="flex pb-4 justify-between">
              <div className="text-2xl font-bold">Employee Details</div>
            </div>
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={employeeColDefs}
              rowData={employeeRowData}
              suppressRowTransform={true}
              pagination={true}
              animateRows={true}
              getRowStyle={(params) => {
                if (params.rowIndex % 2 === 0) {
                  return { background: "#f8f8f8" };
                } else {
                  return { background: "#ffffff" };
                }
              }}
            />
          </div>
        )}
      {companyDetailsData?.adminDetails &&
        companyDetailsData?.adminDetails.length > 0 && (
          <div className="pt-10 pb-5 ag-theme-quartz h-[calc(100vh-50vh)]">
            <div className="flex pb-4 justify-between">
              <div className="text-2xl font-bold">Admin Details</div>
              <PrimaryCTAButton
                disabled={disableDeleteBtn}
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </PrimaryCTAButton>
            </div>
            <AgGridReact
              ref={gridApi}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={rowsData}
              suppressRowTransform={true}
              pagination={true}
              animateRows={true}
              rowSelection={rowSelection}
              onRowSelected={(e: any) => {
                const a =
                  e.api.getSelectedRows().length > 0
                    ? setDisableDeleteBtn(false)
                    : setDisableDeleteBtn(true);
              }}
              getRowStyle={(params) => {
                if (params.rowIndex % 2 === 0) {
                  return { background: "#f8f8f8" };
                } else {
                  return { background: "#ffffff" };
                }
              }}
            />
          </div>
        )}
      {deleteUser && (
        <WarningDialog
          open={deleteUser}
          companyId={companyDetails.companyId}
          userDetails={userDetails}
          onClose={() => {
            setDeleteUser(!deleteUser);
            if (companyDetails?.id) {
              mutateAdminDetails(companyDetails.id, {
                onSuccess: (data: any) => {
                  setCompanyDetailsData(data);
                  setDisableDeleteBtn(true);
                },
                onError: (error: any) => {
                  toast(error.data.message, { type: "error", autoClose: 2000 });
                },
              });
            }
          }}
        />
      )}
    </VStack>
  );
}

export default CompanyDetailsPage;
